import React, { useRef, useState } from "react";
import Slider from "react-slick";

const Journey = ({ data }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const gotoSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 600,
    autoplaySpeed: 5000,
    cssEase: "linear",
    beforeChange: (_, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="journey">
      <div className="container">
        <div className="row">
          <div className="web-container">
            <div className="title">
              <h2
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
              >
                Our journey
              </h2>
            </div>
            <div
              className="journeryslider"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="600"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
              data-aos-delay="300"
            >
              <Slider ref={sliderRef} {...settings}>
                {data.map((period, index) => (
                  <div key={index}>
                    <div className="journeyrow d-flex justify-content-between">
                      <div className="journeyimg">
                        <img
                          src={period.image}
                          alt={`Journey in ${period.decade}`}
                        />
                      </div>
                      <div className="journeycont">
                        <h3>
                          {period.decade} <span>{period.subtitle}</span>
                        </h3>
                        {period.descriptions.map((desc, idx) => (
                          <p key={idx}>{desc}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>

              {/* Custom dots */}
              <ul className="slick-dots">
                {data.map((_, index) => (
                  <li
                    key={index}
                    className={index === currentSlide ? "slick-active" : ""}
                  >
                    <button onClick={() => gotoSlide(index)}>
                      {data[index].decade}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
