import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Numbers } from "./components/Numbers";
import Slider from "react-slick";
import Banner02 from "./images/banner02.jpg";
import Banner03 from "./images/The Hive Web banner.webp";
import malwaBanner03 from "./images/malwa encalave banner.webp";
import Studio102 from "./images/studio-102.png";
import Elementone from "./images/Element_one_logo.png";
import Thehive from "./images/The Hive Logo.png";
import themalwaencalve from "./images/malwa logo.webp";
import Meranogreen from "./images/stya studio banner01.webp";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

export const Home = () => {
  var decodeHTML = function (html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  function createMarkup(content) {
    return { __html: decodeHTML(content) };
  }

  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        "https://www.satyagroups.in/api/homes.php"
      );
      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log("no data");
    return null;
  }
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 600,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <div className="jslider">
        <div className="container">
          <div className="row">
            <Slider {...settings}>
              <div>
                <div className="homeslider slide02">
                  <div className="homcolft">
                    <div className="slidecont">
                      <h2
                        className="slidertitle"
                        data-aos="flip-down"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        YOUR INDEPENDENT
                        <br /> LIFESTYLE BEGINS WITH
                        <br /> STUDIO 102
                      </h2>
                      <div
                        className="slidephara ro"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        {/* <ul>
                          <li>SERVICE APARTMENTS.</li>
                          <li>OFFICE SPACES</li>
                          <li>MULTIPLEX</li>
                          <li>FOOD HUB</li>
                        </ul> */}
                        <p className="col-lg-10 col-12">
                          Welcome to Studio 102, where the comforts of home meet
                          the luxury of a hotel, all in the convenience of your
                          own apartment. Whether you're seeking a place to stay,
                          an investment for real estate appreciation, or a
                          second income source through leasing, Studio 102
                          offers the ideal solution.
                        </p>
                      </div>
                      <div
                        className="webclick"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <NavLink to="/project/studio-102-at-the-hive">
                          <span>More Details</span>
                        </NavLink>
                      </div>
                      <div
                        className="projectlogo"
                        data-aos="fade-in"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={Studio102} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="homcolrt"
                    data-aos="fade-left"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-delay="600"
                    data-aos-easing="ease-in-sine"
                  >
                    <img src={Meranogreen} />
                    <span className="img_type">Actual Image</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="homeslider slide02">
                  <div className="homcolft">
                    <div className="slidecont">
                      <h2
                        className="slidertitle"
                        data-aos="flip-down"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        High
                        <br />
                        street of
                        <br />
                        opportunities
                      </h2>
                      <div
                        className="slidephara"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <p>
                          Designed to exude energy through its sophisticated
                          blend of retail, F&B, multiplex, entertainment,
                          offices and studio apartments, it’s a mega high street
                          located just of Dwarka Expressway in sector 102,
                          Gurugram
                        </p>
                      </div>
                      <div
                        className="webclick"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <Link to="project/retail-at-the-hive">
                          <span>More Details</span>
                        </Link>
                      </div>
                      <div
                        className="projectlogo"
                        data-aos="fade-in"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={Thehive} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="homcolrt"
                    // data-aos="fade-left"
                    //
                    // data-aos-duration="600"
                    // data-aos-once="true"
                    // data-aos-delay="600"
                    // data-aos-easing="ease-in-sine"
                  >
                    <img src={Banner03} />
                    <span class="img_type">Actual Image</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="homeslider slide02">
                  <div className="homcolft">
                    <div className="slidecont">
                      <h2
                        className="slidertitle"
                        data-aos="flip-down"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        PREMIUM ROW
                        <br />
                        VILLAS FOR THE
                        <br />
                        INDEPENDENT YOU
                      </h2>
                      <div
                        className="slidephara"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <p className="col-lg-10 col-12">
                          Placed within the Malwa County community, Malwa
                          Enclave is a new collection of independent row villas.
                          These duplex villas have an exclusivity of 94
                          independent units, with landscaped green environment.
                          Its high-end luxury specifications, with professional
                          security and modern architecture paves way for a
                          comfortable and convenient lifestyle.
                        </p>
                      </div>
                      <div
                        className="webclick"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <Link to="project/malwa-enclave">
                          <span>More Details</span>
                        </Link>
                      </div>
                      <div
                        className="projectlogo"
                        data-aos="fade-in"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={themalwaencalve} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="homcolrt"
                    // data-aos="fade-left"
                    //
                    // data-aos-duration="600"
                    // data-aos-once="true"
                    // data-aos-delay="600"
                    // data-aos-easing="ease-in-sine"
                  >
                    <img src={malwaBanner03} />
                    <span class="img_type">artistic Image</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="homeslider slide02">
                  <div className="homcolft">
                    <div className="slidecont">
                      <h2
                        className="slidertitle"
                        data-aos="flip-down"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        Your Apartment <br /> Our Service
                      </h2>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        Lavish serviced apartments located at Element One, a
                        vibrant blend of high-street retail and private business
                        suites located in Sector 47 & 49 in the heart of
                        Gurugram
                      </p>
                      <div
                        className="webclick"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                      >
                        <Link to="/project/serviced-apartments-at-element-one">
                          <span>More Details</span>
                        </Link>
                      </div>
                      <div
                        className="projectlogo"
                        data-aos="fade-in"
                        data-aos-duration="600"
                        data-aos-once="true"
                        data-aos-offset="10"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={Elementone} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="homcolrt"
                    data-aos="fade-left"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-delay="600"
                    data-aos-easing="ease-in-sine"
                  >
                    <img src={Banner02} />
                    {/* <span class="img_type">artistic Image</span> */}
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {pagedata.projects ? (
        <div className="hprojects">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                >
                  <h2>Projects</h2>
                </div>
                <div
                  className="subtitle"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-sine"
                >
                  <h3>Ongoing</h3>
                </div>
                <div className="projectchart">
                  {pagedata.projects.ongoing[0] ||
                  pagedata.projects.ongoing[1] ? (
                    <div className="projectrow">
                      {/* Ongoing Loop Start  */}
                      {pagedata.projects.ongoing[0] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-right"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[0].slug}>
                            <img src={pagedata.projects.ongoing[0].image} />
                            <span className="img_type">{pagedata.projects.ongoing[0].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[0].title},
                              {pagedata.projects.ongoing[0].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {pagedata.projects.ongoing[1] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-left"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[1].slug}>
                            <img src={pagedata.projects.ongoing[1].image} />
                            <span className="img_type">{pagedata.projects.ongoing[1].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[1].title},
                              {pagedata.projects.ongoing[1].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {/* Ongoing Loop End */}
                    </div>
                  ) : null}
                  {pagedata.projects.ongoing[2] ||
                  pagedata.projects.ongoing[3] ? (
                    <div className="projectrow">
                      {/* Ongoing Loop Start  */}
                      {pagedata.projects.ongoing[3] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-right"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[3].slug}>
                            <img src={pagedata.projects.ongoing[3].image} />
                            <span className="img_type">{pagedata.projects.ongoing[3].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[3].title},
                              {pagedata.projects.ongoing[3].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {pagedata.projects.ongoing[2] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-right"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[2].slug}>
                            <img src={pagedata.projects.ongoing[2].image} />
                            <span className="img_type">{pagedata.projects.ongoing[2].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[2].title},
                              {pagedata.projects.ongoing[2].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {/* Ongoing Loop End */}
                    </div>
                  ) : null}
                  {pagedata.projects.ongoing[5] ||
                  pagedata.projects.ongoing[6] ? (
                    <div className="projectrow">
                      {/* Ongoing Loop Start  */}
                      {pagedata.projects.ongoing[4] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-right"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[4].slug}>
                            <img src={pagedata.projects.ongoing[4].image} />
                            <span className="img_type">{pagedata.projects.ongoing[4].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[4].title},
                              {pagedata.projects.ongoing[4].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {pagedata.projects.ongoing[5] ? (
                        <div
                          className="projectbox"
                          data-aos="fade-right"
                          data-aos-offset="200"
                          data-aos-duration="600"
                          data-aos-once="true"
                          data-aos-delay="200"
                          data-aos-easing="ease-in-sine"
                        >
                          <Link to={pagedata.projects.ongoing[5].slug}>
                            <img src={pagedata.projects.ongoing[5].image} />
                            <span className="img_type">{pagedata.projects.ongoing[5].imagetype}</span>

                            <h3>
                              {pagedata.projects.ongoing[5].title},
                              {pagedata.projects.ongoing[5].location}
                            </h3>
                          </Link>
                        </div>
                      ) : null}
                      {/* Ongoing Loop End */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="haboutus">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div
                className="title"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
                data-aos-delay="100"
              >
                <h2>About us</h2>
              </div>
              <div className="haboutrow">
                <div
                  className="haboutimg"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="200"
                >
                  <img src={pagedata.aboutus.hpbanner} />
                </div>
                <div className="haboutcont">
                  <h1
                    data-aos="fade"
                    data-aos-offset="300"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="800"
                  >
                    {pagedata.aboutus.hptitle}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      pagedata.aboutus.sdesc
                    )}
                  ></div>
                  <Link class="btnfill button-2" to="/about-satya">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Numbers contact={pagedata.aboutus} />
      {pagedata.category ? (
        <div className="whtwedo">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                >
                  <h2>What we do</h2>
                </div>
                {/* Loop Start */}
                <div className="categoryrow">
                  {/* Loop Start */}
                  {pagedata.category.map((catdata) => (
                    <div
                      className="categorybox"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-delay="300"
                    >
                      <Link to={"/projects/" + catdata.heading}>
                        <img src={catdata.image} />
                        <div className="categorycent">
                          <div className="categorycont">
                            <h2>{catdata.heading}</h2>
                            <p>{catdata.description}</p>
                            <span>Know More</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/* Loop End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </>
  );
};
export default Home;
