import React from "react";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="web-container">
            <div class="d-flex align-items-center justify-content-center bg-light py-5 px-3">
              <div class="F text-center">
                <div
                  className="title"
                  data-aos="zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                >
                  <h2>Thank You</h2>
                </div>

                <p class="text-muted mb-4">
                  Your submission has been received successfully. We will get
                  back to you soon.
                </p>
                <Link to="/">
                  <button className="btnfill button-2">
                    <span>Back to Home </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
